/* General container styling */
.poesie-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
}

/* Header */
.poesie-header {
    text-align: center;
    margin-bottom: 40px;
}

.poesie-header h1 {
    font-size: 3rem;
    margin: 0;
    color: #333;
}

.poesie-header p {
    font-size: 1.2rem;
    color: #666;
}

/* Poem list */
.poesie-list ul {
    list-style: none;
    padding: 0;
}

.poem-item {
    display: flex;
    margin-bottom: 30px;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    border-top: solid;
    padding: 50px 0;
}

.poem-thumbnail {
    width: 30%;
    height: auto;
    margin-right: 20px;
    object-fit: cover;
    border-radius: 8px;
}

.poem-info {
    display: flex;
    flex-direction: column;
}

.poem-title {
    font-size: 1.5rem;
    margin: 0 0 10px;
    color: #333;
}

.poem-text {
    font-size: 1rem;
    color: #666;
    margin: 0;
}

@media(max-width:760px) {
    .poem-item {
       
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        border-top: solid;
        padding: 50px 0;
    }

    .poem-thumbnail {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 30px;
    }
    
}
