/* General styling */

@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

body {
  font-family: Arial, sans-serif;

  box-sizing: border-box;
  max-width: 100vw; /* Ensure body doesn't exceed the viewport width */
}

*,
*::before,
*::after {
  box-sizing: inherit; /* Apply box-sizing to all elements */
}


/* App container */
.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Animation */
@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* Text styling */
h1 {
  font-size: 2.5em;
  margin: 0;
  text-align: center;
  color: #333;
  margin-bottom: 5px;
  font-family: 'Poppinsb', sans-serif;
}

.down {
  margin-top:5%;
}

.black {
  font-family: 'Poppins', sans-serif;

}

h2 {
  color: #333;
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
h4 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
/* Category styling */
.category {
  margin-bottom: 40px;
  padding: 0 15px;
}

.separator {
  order: none;
  height: 3px;
  background-color: black;
}

/* Profile image */
.profile-image {
  width: 100px;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  transition: filter 0.3s ease-in-out;
  text-align: center;
  display: block;
  margin: auto;
  filter:grayscale(1)
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.header-text {
  text-align: left;
  margin-left: 20px;
  display:flex;
  justify-content:space-between;
  flex-direction: row;
  width: 100%;
}

/* Email link */
.email-link {
  font-size: 1.2em;
  color: #fc0008;
    text-decoration: none;
    transition:all 0.3s ease-in-out;
    cursor:pointer;
}
.email-link:hover {
 filter:invert(1);
}

.email-link:hover {
  text-decoration: underline;
}

/* List styling */
ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin: 10px 0;
  font-size: 1.1em;
  color: #555;
}

.portfolio-container {
  padding:20px 80px;
}

/* Responsive design */
@media (max-width: 768px) {
  .header {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .header-text {
      margin-left: 0;
      margin-top: 10px;
  }

  .profile-image {
      margin-bottom: 15px;
  }

  h2 {
      font-size: 1.5rem;
  }

  .category {
      margin-bottom: 30px;
  }

  .portfolio-container {
      padding: 10px;
  }

  .media-title {
    flex-direction:column;
    text-align: center;

  }

  .media-item {
    justify-content: space-between;
    text-align: center;
  }

  .header-text {
    text-align: center;
  margin:auto!important;
    flex-direction: column;
 
  }

  .bispic {
    top: 16% !important;
    left: 33% !important;
  }

  li {
    font-size: 0.9em;
  }
}

.movie-details {
  font-style: italic;
  color: #666;  /* Optional: Make the details a bit lighter for visual hierarchy */
  margin-top: 5px;  /* Add some spacing from the main movie title */
}

/* Movie thumbnail */
/* Media (Movie/Performance/Live) item */
.media-item {
  display: flex;
  align-items: center;
    margin-bottom: 20px;
}
.arrow-sign {
  margin-left: 50px;
  font-size: 1rem;
  font-family: 'PicNic';
  font-weight: inherit;
  min-width: 2ch;

}

.arrow-sign {
  color:rgb(252, 0, 8);
  margin-left: 50px;
  font-size: 1rem;
  font-family: 'PicNic';
  font-weight: inherit;
  transition: all 0.3s ease-in-out;

}

li:hover {
  cursor:pointer;
}

li:hover .arrow-sign {
transform:scale(1.5);
filter: invert(1);
cursor:pointer;

}
/* Thumbnail for Movies and Performances */
.media-thumbnail {
  width: 80px;
  height: auto;
  margin-right: 15px;
  object-fit: cover;
  border-radius: 4px;
}

.media-info {
  display: flex;
  flex-direction: column;
}

.media-title {
  display: flex;
  align-items: center;
  font-size: 1.1em;
}

/* Ensure the media-title (title, author, and year) is on the same line */
.media-title strong {
  margin-right: 8px;
}

.media-details {
  font-style: italic;
  color: #666;
  margin-top: 5px;
}

/* Separator for better spacing */
.separator {
  margin: 30px 0;
}

.bispic {
  filter: url(#shaka);
  position: absolute;
  top: 6%;
  left: 48%;
  z-index: -1;
  opacity: 0.4;
}

/* Responsive Footer Styles */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  /* background-color: black; */
  margin-top: 50px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  fill: rgb(252, 0, 8);
  transition: all 0.3s ease-in-out;
  cursor:pointer;
}

.footer-logo:hover {
  filter:invert(1);
}

.footer-text {
  font-size: 0.9rem;
  margin: 0;
  color:black;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .footer-content {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      padding: 0 2rem;
  }

  .footer-logo {
      margin-bottom: 0;
      margin-right: 1rem;
  }

  .footer-text {
      font-size: 1rem;
      color:black;
  }
}

.none {
width:0;
height:0;
position: absolute;}

/* .dualactu {
  display: flex;
  flex-direction: row;
} */